<template>
    <header class="page-title">
        <h1><i class="bi bi-calculator-fill"></i> 収支一覧</h1>
    </header>

    <ul class="nav nav-tabs px-3">
        <li class="nav-item">
            <router-link :to="{name: 'Profit'}" class="nav-link">月別</router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name: 'ProfitProject'}" class="nav-link active">案件別</router-link>
        </li>
    </ul>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <form-select-search v-model="project" :options="projects" option_value="project_id" option_label="project_name" placeholder="-- 案件を選択 --" required :disabled="projects.length === 0"></form-select-search>
            </div>
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-3">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
            <div class="col-9 d-flex justify-content-end">
                <span v-if="$store.getters['auth/canExportCsv']()" class="dropdown me-3">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-download"></i> CSV
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button" @click="exportCsv()">CSVエクスポート</button></li>
                        <li><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート履歴</router-link></li>
                    </ul>
                </span>
                <button v-else type="button" class="btn btn-outline-primary me-3" disabled><i class="bi bi-download"></i> CSV</button>
                <div class="dropdown" v-if="$store.getters['auth/canEditProfit']()">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        一括操作
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button" @click="bulkFix()">一括確定</button></li>
                    </ul>
                </div>
            </div>
        </form>

        <div class="bg-light p-3 mb-3">
            <bar-chart :dataset="chart_data" :loading="loading"></bar-chart>
        </div>

        <table class="table">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">年月</th>
                    <th class="text-center">売上</th>
                    <th class="text-center">原価</th>
                    <th class="text-center">粗利</th>
                    <th class="text-center">粗利率</th>
                    <th class="text-center">状態</th>
                    <th class="text-center"></th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(10)" :key="i">
                    <td class="text-primary"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-center"><text-loader :width="6"></text-loader></td>
                    <td>
                        <button class="btn btn-primary btn-sm me-3" disabled>詳細/入力</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="profits.length === 0">
                <td colspan="6" class="text-center p-3">該当データがありません</td>
            </tbody>
            <tbody v-else>
                <tr v-for="profit of profits" :key="profit.profit_id">

                    <td class="text-primary"><router-link :to="{name: 'Profit', query: {month: profit.month}}">{{ profit.month_display }}</router-link></td>

                    <td class="text-end">{{ $helper.number(profit.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(profit.cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(profit.gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(profit.sales_price, profit.gross_profit)">{{ profit.gross_profit_rate }}</td>
                    <td class="text-center">
                        <span v-if="profit.is_calculating" class="text-warning">計算中</span>
                        <span v-else-if="profit.is_fixed">確定</span>
                        <span v-else class="text-danger">未確定</span>
                    </td>
                    <td>
                        <router-link :to="{name: 'ProfitEdit', params: {profit: profit.profit_id}}" class="btn btn-primary btn-sm me-3">詳細/入力</router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="loading">
                <tr>
                    <td>合計</td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td>合計</td>
                    <td class="text-end">{{ $helper.number(total_sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(total_cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(total_sales_price, total_gross_profit)">{{ total_gross_profit_rate }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>

<script>
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormInputMonth from '@/components/forms/FormInputMonth';
import TextLoader from '@/components/tools/TextLoader';
import Profit from "@/models/entities/profit";
import Project from "@/models/entities/project";
import BarChart from '@/components/charts/BarChart';

export default {
    name: 'Report',
    components: {
        FormSelectSearch,
        FormInputMonth,
        TextLoader,
        BarChart,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            // month: thisMonth(), // computed に移動
            loading: false,
            projects: [],
            profits: [],
        }
    },
    mounted() {
        if (this.$route.query.project) {
            this.project = this.$route.query.project;
        }

        this.fetchMaster();

        if (this.project !== null) {
            this.fetch();
        }
    },
    methods: {
        fetchMaster()
        {
            this.$http.get('/list/project', {params: {month_from: this.$store.state.condition.month_from, month_to: this.$store.state.condition.month_to}})
            .then((response) => {
                this.projects = response.data.map((row) => {return new Project(row)});
            });
        },
        fetch() {
            this.loading = true;
            this.profits.splice(0);
            this.$http.get('/profit', {params: {
                project_id: this.project,
                month_from: this.month_from,
                month_to: this.month_to
            }})
            .then((response) => {
                for (let row of response.data) {
                    this.profits.push(new Profit(row));
                }
                this.loading = false;
            });
        },
        bulkFix()
        {
            this.startScreenLoading();
            this.$http.post('/profit/bulk-fix', {
                project_id: this.project,
                month_from: this.month_from,
                month_to: this.month_to
            })
            .then((response) => {
                this.showMessage(response.data.updated + '件を確定しました');
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });

        },
        profit_rate_class(sales_price, gross_profit)
        {
            if (sales_price === 0) {
                return null;
            } else {
                const rate = gross_profit * 100 / sales_price;
                if (rate >= this.$store.state.auth.company.profit_rate_safe) {
                    return "text-success";
                } else if (rate < this.$store.state.auth.company.profit_rate_danger) {
                    return "text-danger";
                } else {
                    return "text-warning";
                }
            }
        },
        exportCsv() {
            this.startScreenLoading();

            this.$http.post('/profit/export', {
                project_id: this.project,
                month_from: this.month_from,
                month_to: this.month_to,
            })
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
    computed: {
        project: {
            get() {
                return this.$store.state.condition.project;
            },
            set(value) {
                this.$store.commit('condition/setProject', value);
            },
        },
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        total_sales_price() {
            return this.profits.reduce((total, profit) => total + profit.sales_price, 0);
        },
        total_cost_total() {
            return this.profits.reduce((total, profit) => total + profit.cost_total, 0);
        },
        total_gross_profit() {
            return this.profits.reduce((total, profit) => total + profit.gross_profit, 0);
        },
        total_gross_profit_rate() {
            if (this.total_sales_price === 0) {
                return "-";
            } else {
                return (this.total_gross_profit * 100 / this.total_sales_price).toFixed(1) + '%';
            }
        },
        chart_data() {
            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.month < b.month ? -1 : 1;
            });
            return sorted.map(profit => {
                return {
                    label: profit.month,
                    values: [
                        {
                            key: '売上',
                            value: profit.sales_price,
                        },
                        {
                            key: '粗利',
                            value: profit.gross_profit,
                        }
                    ]
                }
            })
        }
    },
}
</script>

<style scoped>
</style>
